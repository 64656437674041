<template>
    <v-row>
        <v-col cols="6">
            <vue-monaco-editor
                v-model="code"
                :options="options"
                :theme="theme"
                class="editor"
                language="html"
            />
        </v-col>
        <v-col cols="6">
            <iframe :src="'data:text/html;base64,' + base64Code" frameborder="0"></iframe>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "CodeEditor",
    props: ["value"],
    data() {
        return {
            code: this.value,
            theme: "vs-dark",
            options: {
                automaticLayout: true,
                formatOnType: true,
                formatOnPaste: true
            }
        };
    },
    mounted() {
        if (this.$vuetify.theme.dark) {
            this.theme = "vs-dark";
        } else {
            this.theme = "vs-light";
        }
    },
    watch: {
        code(val) {
            if (val === "") {
                val = null;
            }
            this.$emit("input", val);
        }
    },
    computed: {
        base64Code() {
            if (!this.code) {
                return btoa("");
            }
            return btoa(this.code);
        }
    }
};
</script>

<style scoped>
.editor {
    min-height: 75vh;
}

iframe {
    width: 100%;
    height: 100%;
    border: #d7d7d7 solid 1px;
}
</style>