<template>
    <div class="ml-10 mr-10">
        <TitleBar :loading="loading" :text="this.$t('company.settings.customisation')"></TitleBar>

        <v-form @submit.prevent="formSubmit">
            <v-row>
                <v-col md="9">
                    <v-select
                        v-model="api_language"
                        :disabled="loading"
                        :items="api_languages"
                        :label="$t('company.language')"
                        outlined
                    ></v-select>

                    <v-textarea
                        v-model="document_footer"
                        :disabled="loading"
                        :hint="$t('company.documents.footer_hint')"
                        :label="$t('company.documents.footer')"
                        class="mb-10"
                        outlined
                        persistent-hint
                    >
                    </v-textarea>

                    <v-switch
                        v-model="include_facturx"
                        :disabled="loading"
                        :label="$t('company.documents.include_facturx')"
                        color="secondary"
                    ></v-switch>
                </v-col>
                <v-col md="3">
                    <p>{{ $t("company.expense_report.color") }}</p>

                    <v-color-picker
                        v-model="color"
                        :disabled="loading"
                        class="ma-2 mb-4"
                        mode="hexa"
                    ></v-color-picker>

                    <v-switch
                        v-model="document_color"
                        :disabled="color === null || loading"
                        :label="$t('company.documents.use_color')"
                        color="secondary"
                    ></v-switch>
                </v-col>
            </v-row>

            <v-row>
                <v-col md="4">
                    <p>{{ $t("company.settings.logo.current") }}:</p>
                    <v-img
                        :src="logo_url"
                        lazy-src="@/assets/images/default_logo.jpg"
                        max-width="100%"
                    >
                        <template v-slot:placeholder>
                            <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                            >
                                <v-progress-circular
                                    v-if="logo != null"
                                    color="grey lighten-5"
                                    indeterminate
                                ></v-progress-circular>
                                <v-icon v-else color="white" large>
                                    mdi-close-circle-outline
                                </v-icon>
                            </v-row>
                        </template>
                    </v-img>
                    <v-switch
                        color="secondary"
                        v-model="document_logo"
                        :disabled="logo === null || loading"
                        :label="$t('company.documents.use_logo')"
                    ></v-switch>
                </v-col>
                <v-col md="8">
                    <p>{{ $t("company.settings.logo.change") }}:</p>
                    <FileUpload accept="image/*" class="mb-2" v-on:file="filesChange"></FileUpload>
                    <p>{{ $t("company.settings.logo.size_infos") }}</p>

                    <v-simple-table v-show="logo_upload !== null" class="mt-3">
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td>{{ truncate(logo_upload.name) }}</td>
                                <td style="width: 94px;">
                                    <v-progress-linear
                                        :color="logo_upload.step === 'uploading' ? 'primary' : (logo_upload.step === 'success' ? 'green' : 'red')"
                                        :indeterminate="logo_upload.step === 'uploading'"
                                        height="6"
                                        rounded
                                        value="100"

                                    ></v-progress-linear>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                </v-col>
            </v-row>

            <v-expansion-panels :disabled="loading" class="mb-5">
                <v-expansion-panel>
                    <v-expansion-panel-header>

                        <template v-slot:default="{ open }">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    {{ $t("company.customisation.email_template") }}
                                </v-col>
                                <v-col
                                    class="text--secondary"
                                    cols="6"
                                    style="text-align: right;"
                                >
                                    <v-fade-transition leave-absolute>
                                        <span
                                            v-if="open"
                                            key="0"
                                            class="mr-3"
                                        >
                                            <a href="https://docs.accounting.sh"
                                               style="text-decoration: none;color:inherit;" target="_blank"
                                               @click.stop=""><v-icon>mdi-help-circle-outline</v-icon> {{
                                                    $t("company.customisation.email_template_help")
                                                }}</a>
                                        </span>
                                    </v-fade-transition>
                                </v-col>
                            </v-row>
                        </template>


                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <CodeEditor v-model="email_template"></CodeEditor>
                    </v-expansion-panel-content>
                </v-expansion-panel>


                <v-expansion-panel>
                    <v-expansion-panel-header>

                        <template v-slot:default="{ open }">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    {{ $t("company.customisation.document_template") }}
                                </v-col>
                                <v-col
                                    class="text--secondary"
                                    cols="6"
                                    style="text-align: right;"
                                >
                                    <v-fade-transition leave-absolute>
                                        <span
                                            v-if="open"
                                            key="0"
                                            class="mr-3"
                                        >
                                            <a href="https://docs.accounting.sh"
                                               style="text-decoration: none;color:inherit;" target="_blank"
                                               @click.stop=""><v-icon>mdi-help-circle-outline</v-icon> {{
                                                    $t("company.customisation.document_template_help")
                                                }}</a>
                                        </span>
                                    </v-fade-transition>
                                </v-col>
                            </v-row>
                        </template>


                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <CodeEditor v-model="document_template"></CodeEditor>
                    </v-expansion-panel-content>
                </v-expansion-panel>

            </v-expansion-panels>

            <v-btn
                :loading="loading"
                class="mt-2"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $t("company.customisation.update") }}
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import FileUpload from "@/components/FileUpload.vue";
import Accounting from "@/helpers/Accounting";
import Settings from "@/helpers/Settings";
import CodeEditor from "@/components/CodeEditor.vue";

export default {
    name: "Customisation",
    components: {CodeEditor, FileUpload, TitleBar},
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.settings"),
                to: "/company/settings",
                exact: true
            },
            {
                text: this.$t("company.settings.customisation"),
                to: "/company/settings/customisation",
                exact: true
            }
        ]);

        this.api_languages = require("@/assets/api_lang.json");
        this.loading = true;

        this.settings = new Settings();
        this.settings.addSetting(
            "/companies/" + this.$store.state.company.uuid + "/settings/preferred~locale", value => {
                this.api_language = value;
            },
            () => this.api_language
        );
        this.settings.addSetting(
            "/companies/" + this.$store.state.company.uuid + "/settings/color",
            value => {
                this.color = value;
            },
            () => {
                let color = this.color;
                if (this.color.hex !== undefined) {
                    color = this.color.hex;
                }
                return color;
            }
        );
        this.settings.addSetting(
            "/companies/" + this.$store.state.company.uuid + "/settings/logo",
            value => {
                this.logo = value;
            },
            () => this.logo
        );
        this.settings.addSetting(
            "/companies/" + this.$store.state.company.uuid + "/settings/document~footer",
            value => {
                this.document_footer = value;
            },
            () => this.document_footer
        );
        this.settings.addSetting(
            "/companies/" + this.$store.state.company.uuid + "/settings/document~logo",
            value => {
                this.document_logo = value === "true";
            },
            () => this.document_logo ? "true" : null
        );
        this.settings.addSetting(
            "/companies/" + this.$store.state.company.uuid + "/settings/document~color",
            value => {
                this.document_color = value === "true";
            },
            () => this.document_color ? "true" : null
        );
        this.settings.addSetting(
            "/companies/" + this.$store.state.company.uuid + "/settings/email~template",
            value => {
                this.email_template = value;
            },
            () => this.email_template
        );
        this.settings.addSetting(
            "/companies/" + this.$store.state.company.uuid + "/settings/document~template",
            value => {
                this.document_template = value;
            },
            () => this.document_template
        );
        this.settings.addSetting(
            "/companies/" + this.$store.state.company.uuid + "/settings/include~facturx",
            value => {
                this.include_facturx = value === "true";
            },
            () => this.include_facturx ? "true" : null
        );

        this.loading = true;
        this.settings.get()
            .finally(() => {
                this.loading = false;
            });
    },
    data() {
        return {
            loading: false,
            api_languages: [],
            api_language: null,
            document_footer: null,
            document_logo: null,
            document_color: null,
            color: null,
            logo_upload: null,
            logo_url: null,
            logo: null,
            settings: null,
            email_template: null,
            document_template: null,
            include_facturx: null
        };
    },
    methods: {
        formSubmit() {
            this.loading = true;
            this.settings.update()
                .finally(() => {
                    this.loading = false;
                    this.$store.commit("SET_ALERTS", [{
                        type: "success",
                        text: this.$t("company.updated")
                    }]);
                });
        },
        filesChange(file) {
            let formData = new FormData();
            formData.append("file", file);
            formData.append("name", file.name);

            this.logo_upload = {
                name: file.name,
                step: "uploading"
            };

            // Upload file
            Accounting.upload("/documents", formData)
                .then((response) => {
                    this.logo = response.data.uuid;
                    this.logo_upload = {
                        name: file.name,
                        step: "success"
                    };
                })
                .catch(() => {
                    this.logo_upload = {
                        name: file.name,
                        step: "error"
                    };
                });
        },
        truncate(str) {
            const MAX_LENGTH = 42;
            if (str.length <= MAX_LENGTH) {
                return str;
            }

            return str.substring(0, MAX_LENGTH) + "...";
        }
    },
    watch: {
        logo() {
            if (this.logo != null) {
                Accounting.blob("/documents/" + this.logo + "/view")
                    .then(blob => {
                        this.logo_url = URL.createObjectURL(blob);
                    });
            }
        }
    }
};
</script>

<style scoped>

</style>